
.mainnav__logo {
  @extend %icon-comspace-logo--white;

  width: $mainnav-logo-width;
  height: $mainnav-logo-height;

  background-repeat: no-repeat;
  background-size: $mainnav-logo-width auto;
  background-position: left center;
}

.mainnav__menu__second-level__close--icon {
  @extend %icon-close--white;
  width: 35px;
  height: 35px;
  display: inline-block;
  background-color: black;
  background-size: contain;
}

.mainnav__menu__contact-teaser{

  &__phone--icon:before {
    @extend %icon-phone--black;
  }

  &__mail--icon:before {
    @extend %icon-mail--black;
  }

  &__phone--icon::before ,
  &__mail--icon::before {
    content: '';
    background-size: cover;
    display: inline-block;
    position: absolute;
    left: -34px;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}

.mainnav__burger {
  // @extend %icon-burger-menu--white;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: none;
  position: relative;
  cursor: pointer;
  > i {
    display: block;
    background-color: white;
    width: 30px;
    height: 3px;
    margin: auto;
    position: relative;
    transition: 0.3s all ease-in-out;
    &:after {
      position: absolute;
      content: ' ';
      display: block;
      background-color: white;
      width: 30px;
      height: 3px;
      bottom: -10px;
      transition: 0.3s all ease-in-out;
      transform-origin: center center;
    }
    &:before {
      position: absolute;
      content: ' ';
      display: block;
      background-color: white;
      width: 30px;
      height: 3px;
      top: -10px;
      transition: 0.3s all ease-in-out;
      transform-origin: center center;
    }
  }
}

.menu--active {
  .mainnav__burger {
    > i {
      background-color: #ffffff00;
      &:after {
        bottom: 0;
        transform: rotate(45deg);
      }
      &:before {
        top: 0;
        transform: rotate(-45deg);
      }
    }
  }
}

.mainnav__menu__second-level__toggle {
  @extend %icon-plus--white;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 50px;
  .active & {
    @extend %icon-minus--black;
  }
}

@include media-breakpoint-down(md) {
  body.menu--active {
    overflow:hidden;
  }
  .mainnav {
    --mainnav-bgcolor: #{$mainnav-bgcolor};
    --mainnav-textcolor: #{$mainnav-textcolor};
    background-color: $mainnav-bgcolor;
    color: $mainnav-textcolor;

    position: fixed;
    top: 0;
    width: 100%;
    z-index: 110;

    color: var(--mainnav-textcolor);
    font-family: $mainnav-fontfamily;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    user-select: none;

    &__logo {
      margin-left: 15px;
      flex: 1 1 auto;
    }

    &__header {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      min-height: 80px;
      align-content: center;
    }
    >.container {
      margin: 0;
      padding: 0;
      max-width: unset;
      transition: 0.3s all ease-in-out;
      height: 80px;
      overflow: hidden;
    }
    .menu--active & {
      >.container {
        overflow: unset;
        height: 100vh;
      }
    }
    &__meta {
      padding: 18px 15px 17px 15px;
      ul {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        gap: 15px;
        flex-wrap: wrap;
        li {
          flex: 1 1 auto;
          padding: 0;
          margin: 0;
          border: 1px solid white;
          text-align: center;
          a {
            line-height: 37px;
            color: white;
            padding: 0 25px;
            display: block;
            font-weight: bold;
            &:hover,
            &:active,
            &:focus {
              background-color: #FFFFFF4D;
            }
          }
        }
      }
    }

    &__burger {
      width: 50px;
      margin-right: 25px;
      display: flex;
    }

    &__body {
      flex: 1 1 100%;
      flex-direction: column-reverse;
      display: flex;
      // max-height: 0;
      transition: 0.3s all ease-in-out;
      opacity: 0;
      // overflow: hidden;
      .menu--active & {
        opacity: 1;
        transform: translateY(0);
        // max-height: 100vh;
      }
    }

    &__menu {
      padding-top: 6px;
      &__contact-teaser {
        display: none;
      }
      &__link {
        flex: 1 1 auto;
        padding: 0 15px;
      }
      &__item {
        transition: 0.3s all ease-in-out;
        &__teaser {
          display: none;
        }

        &--folder {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .mainnav__menu__link {
            padding-right: 0;
          }
        }

        &.active {
          background-color: white;
          > a {
            // color: black;
            color: black;
          }
          .mainnav__menu__second-level {
            max-height: 100vh;
          }
        }

        a {
          color: white;
          display: block;
          line-height: 48px;
          font-size: 18px;
          font-weight: bold;
        }
      }

      &__second-level {
        background-color: white;
        flex: 1 1 100%;
        max-height: 0;
        overflow: hidden;
        transition: 0.3s all ease-in-out;
        a {
          color: #626060;
          font-weight: normal;
          &:hover,
          &:active,
          &:focus {
            background-color: #F0EFEF;
            color: black;
          }

          .link-description {
            display: none;
          }
        }
        &__hero {
          display: none;
        }
        &__toggle {
          margin: 0 25px;
        }
        &__close {
          &--wrapper {
            display: none!important;
          }
        }
      }


      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          margin: 0;
          padding: 0;
        }
      }
    }

    .container {
      padding: 0;
      max-width: unset;
    }
  }
}

@include media-breakpoint-up(lg) {
  .mainnav {
    --mainnav-bgcolor: #{$mainnav-bgcolor};
    --mainnav-textcolor: #{$mainnav-textcolor};
    background-color: $mainnav-bgcolor;
    color: $mainnav-textcolor;

    position: fixed;
    top: 0;
    width: 100%;
    z-index: 110;

    color: var(--mainnav-textcolor);
    font-family: $mainnav-fontfamily;
    > .container{
      display: flex;
    }

    &__meta {
      font-size: 14px;
      font-weight: bold;

      ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        list-style: none;
        padding: 0;
        margin: 0;
      }

      &__item {
        padding: 0;
        margin: 0;

        a {
          color: white;

          // &:hover {
          //   text-decoration: underline;
          // }
          position: relative;
          &:hover, &.active {
            &:after {
              width: 100%;
            }
          }
          &:after {
            content: '';
            border-bottom: 2px solid white;
            position: absolute;
            left: 0;
            width: 0;
            bottom: -2px;
            transition: 0.3s all ease-in-out;
          }
        }

        &:after {
          content: '|';
          padding: 0 15px;
        }

        &:last-child {
          &:after {
            content: none;
          }
        }
      }
    }

    &__menu {
      font-weight: bold;

      >ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        list-style: none;
        padding: 0;
        margin: 0;
        gap: 30px;
        >li {
          >a {
            line-height: 67px;
            display: inline-block;
            position: relative;
          }
          &:hover, &.active {
            > a {
              &:after {
                width: 100%;
              }
            }
          }
          > a:after {
            content: '';
            border-bottom: 2px solid white;
            position: absolute;
            left: 0;
            width: 0;
            bottom: 17px;
            transition: 0.3s all ease-in-out;
          }
        }
      }

      &__item {
        padding: 0;
        margin: 0;

        a {
          color: white;
          position: relative;

          &:hover {
            // text-decoration: underline;
          }
        }
        &--folder {
          &.no-events {
            pointer-events: none;
          }
        }
      }


      &__second-level {
        // display: none;
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        padding: 40px 0;
        background-color: white;
        color: black;
        // height: calc(100vh - 100px);
        transition: 0.4s all ease-in-out;
        opacity: 0;
        transform: translateY(-15px);
        pointer-events: none;
        &__nav {

          a {
            display: block;
            color: $black;
            &:hover, &.active {
              color: $cyan;
              div {
                &:after {
                  width: 100%;
                }
              }
            }

            &:hover p{
              color: $cyan;
            }

            div {
              position: relative;
              display: inline-block;
              &:after {
                content: '';
                border-bottom: 2px solid $cyan;
                position: absolute;
                left: 0;
                width: 0;
                bottom: 0px;
                transition: 0.3s all ease-in-out;
              }
            }
          }
        }

        .mainnav__menu__item.hover-allowed:hover & {
          // display: block;
          opacity: 1;
          transform: translateY(0);
          pointer-events: all;
        }

        &__list {
          // flex: 1 1 66%;
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          column-gap: 95px;

          li {
            margin: 0;
            padding: 0;
            flex: 0 1 calc(50% - 95px);
          }
        }

        &__hero {
          flex: 0 0 66%;
          margin-bottom: 5px;
          font-size: 16px;
          &--text {
            color: #000000;
          }
          a {
            display: inline-block;

            &:hover {
              color: #000000B3;
              &:after {
                color: #000000B3;
              }
            }
          }
          > a {
            display: block;
          }
          &:hover, &.active {
            .mainnav__menu__second-level__top-link {
              color: $cyan;
              &:before {
                width: 100%;
              }
            }
          }
          .mainnav__menu__second-level__top-link {
            &:before {
              content: '';
              border-bottom: 2px solid #000000B3;
              position: absolute;
              left: 0;
              width: 0;
              bottom: -2px;
              transition: 0.3s all ease-in-out;
            }
          }
        }

        &__close {
          &--wrapper {
            display: none;
          }
          &--icon {
            cursor: pointer;
          }
        }

        &__toggle {
          display: none!important;
        }

        &__top-link {
          margin-bottom: 35px;
          font-size: 24px;
          position: absolute;
          color: #000000;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          &:after {
            padding-left: 0.5em;
            content: '→'!important;
            position: static!important;
          }
        }

        &__nav {
          flex: 0 0 66%;
          border-right: 1px solid #E0DCDC;
        }

        .container {
          display: flex;
          flex-wrap: wrap;
        }

        .mainnav__menu__contact-teaser {
          flex: 1 1 33%;
          padding-left: 95px;

          a {
            color: $black;
          }

          &__headline {
            font-size: 16px;
            margin-bottom: 10px;
          }

          &__image {
            display: block;
            max-width: 190px;
            margin-bottom: 10px;
          }

          &__name {
            font-size: 16px;
            margin-bottom: 6px;
          }

          &__position {
            font-weight: normal;
            font-size: 14px;
            margin-bottom: 6px;
          }

          &__phone {
            font-weight: normal;
            font-size: 12px;
            margin-bottom: 6px;
          }

          &__mail {
            font-weight: normal;
            font-size: 12px;
          }

          &__phone ,
          &__mail {
            a {
              position: relative;
              margin-left: 34px;
              &:hover, &.active {
                &:after {
                  width: 100%;
                }
              }
              &:after {
                content: '';
                border-bottom: 2px solid black;
                position: absolute;
                left: 0;
                width: 0;
                bottom: -2px;
                transition: 0.3s all ease-in-out;
              }
            }
          }

        }

        .mainnav__menu__link > .link-description {
          font-weight: normal;
          font-size: 14px;
          color: $grey;
          margin-bottom: 1rem;
        }

        // .mainnav__menu__item__teaser {
        //   font-weight: normal;
        //   font-size: 14px;
        //   color: $grey;
        // }

        &.hidden {
          display: none!important;
        }

      }
    }
    &:after {
      content: ' ';
      position: absolute;
      left: 0;
      right: 0;
      height: 15px;
      background-color: $mainnav-bgcolor;
      z-index: 111;
      bottom: 0;
      pointer-events: none;
    }
  }

  .mainnav__header {
    min-height: 100px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 38px 15px 0;

  }

  .mainnav__button,
  .mainnav__logo {
    z-index: 110;
  }

  .mainnav__body {
    // background-color: $mainnav-bgcolor;
    // background-color: var(--mainnav-bgcolor);

    // transform: translateY(-100%);
    // transition: transform .5s ease-in-out .5s;

    // position: absolute;
    top: 0;
    // width: 100%;
    // height: 100vh;
    // overflow: auto;
    flex: 1 1 auto;
    padding-top: 12px;
  }

  .mainnav__search {
    color: $mainnav-search-input-textcolor;

    &__input {
      width: 100%;
      color: $mainnav-search-input-textcolor;
      background-color: $transparent;
      border: none;
      border-bottom: $border-width solid $mainnav-search-input-textcolor;
      padding: $mainnav-search-input-padding-top 0 0;

      &:focus {
        outline: none;
      }
    }
  }


  body:after {
    transition: 0.3s all ease-in-out;
    opacity: 0;
    content: ' ';
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000080;
    z-index: 0;
  }
  body.overlay-darken:after {
    opacity: 1;
  }
}

@include media-breakpoint-up(md) {
  .mainnav__menu__second-level__close--wrapper {
    display: flex;
    flex: 0 0 33%;
    justify-content: flex-end;
    align-items: flex-start;
  }
}
