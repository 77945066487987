.stage__breadcrumb {
  flex: 1 1 auto;

  &__linklist {
    list-style: none;
    padding-left: 0;
  }

  &__item {
    display: inline-block;

    &+.stage__breadcrumb__item:before {
      content: '/';
    }

    & a {
      &:hover {
        color: #212529;
        border-bottom-color: #212529;
        opacity: .8;
      }
    }
  }

  &__link {
    @extend .link;
  }

  &__currentPage {
    font-weight: bold;
    border: $link-border-bottom solid transparent;
  }
}

.stage {
  overflow: hidden;
  padding: rem($stage-padding-top) 0 rem($stage-padding-bottom);

  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  position: relative;

  &__wrapper {
    display: flex;
    flex-direction: column;

    >* {
      order: 1;
    }
  }

  [class*=badge-row--xs-bottom] [class*=badge-row--sm-bottom],
  [class*=badge-row--md-bottom],
  [class*=badge-row--lg-bottom],
  [class*=badge-row--xl-bottom],
  [class*=badge-row--bottom] {
    order: 2;
  }

  a:hover ,
  &.stage--withimage a:hover ,
  .stage__share:hover ,
  &.stage--withimage .stage__share:hover  {
    color: $link-hover-color;
    border-color: $link-hover-color;
  }

  &.stage--withimage {
    color: $white;
    background-size: cover;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.3);
    background-blend-mode: overlay;

    .stage__share,
    .stage__breadcrumb__link,
    .stage__share__links__link {
      border-color: $white;
      color: $white;
    }

    picture {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__links {
    margin-bottom: rem($stage-links-margin-bottom);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__headline {
    font-family: $font-family-sans-serif;
    font-size: rem($stage-headline-fontsize) * 0.5;
    line-height: $stage-headline-lineheight;
    font-weight: bold;
    word-break: break-word;
    hyphens: auto;
    margin: rem($stage-headline-margin-top) 0 rem($stage-headline-margin-bottom);

    @include media-breakpoint-up(md) {
      font-size: rem($stage-headline-fontsize) * 0.75;
      // line-height: $stage-headline-lineheight * 0.75;
    }

    @include media-breakpoint-up(lg) {
      font-size: rem($stage-headline-fontsize);
      // line-height: $stage-headline-lineheight;
    }
  }

  &__subline {
    font-size: $stage-subline-fontsize;
    line-height: $stage-subline-lineheight;
    font-weight: $stage-subline-fontweight;
  }

  &__introtext {
    font-size: rem($stage-intro-fontsize);
    line-height: $stage-intro-lineheight;

    @include media-breakpoint-up(lg) {
      width: percentage(8/12);
    }

  }

  &__share {
    display: block;
    opacity: 1;
    transition: opacity .25s ease-in-out;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    flex: 1 1 240px;
    min-width: 240px;
    min-height: 40px;

    li {
      margin-right: 4px;
    }

    &:after {
      content: '';

      @extend %icon-share--black;

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      display: inline-block;
      height: rem($share-icon-size);
      width: rem($share-icon-size);
      vertical-align: middle;
      position: absolute;
      right: 0;

      .stage--withimage & {
        @extend %icon-share--white;
      }

      &:hover , .stage--withimage &:hover {
        @extend %icon-share--cyan;
      }

    }

    &__cta {
      display: inline-block;
      transform: translateY(0);
      position: absolute;
      right: 2.8rem;
      transition: 0.3s all ease-in-out;
      line-height: 40px;

      .active & {
        transform: translateY(-100%);
        opacity: 0;
      }

      &:hover {
        opacity: .7;
      }
    }

    &__links {
      margin-bottom: 0;
      list-style-type: none;
      user-select: none;
      position: absolute;
      right: 2.8rem;
      transform: translateY(100%);
      transition: 0.3s all ease-in-out;
      line-height: 40px;
      opacity: 0;

      .active & {
        display: inline-block;
        transform: translateY(0);
        opacity: 1;
      }

      li {
        display: inline-block;
      }
    }
  }

}

.stage .badge {
  &-row {
    @media (min-width: map-get($grid-breakpoints, xl)) {
      overflow: visible;
    }
  }

  &-row--bottom-right .badge {
    @media (min-width: map-get($grid-breakpoints, xl)+400px) {
      transform: translateX(100%);
      position: absolute;

      &.badge--rotate-left {
        transform: translateY(-65%) translateX(100%) rotateZ(-30deg);
      }

      &.badge--rotate-right {
        transform: translateY(-65%) translateX(100%) rotateZ(30deg);
      }
    }
  }

  &-row--top-right .badge {
    @media (min-width: map-get($grid-breakpoints, xl)+400px) {
      transform: translateY(-50%) translateX(100%);
      position: absolute;

      &.badge--rotate-left {
        transform: translateY(-50%) translateX(100%) rotateZ(-30deg);
      }

      &.badge--rotate-right {
        transform: translateY(-50%) translateX(100%) rotateZ(30deg);
      }
    }
  }

  &-row--top-left .badge {
    @media (min-width: map-get($grid-breakpoints, xl)+400px) {
      transform: translateY(-50%) translateX(-110%);
      position: absolute;

      &.badge--rotate-left {
        transform: translateY(-50%) translateX(-110%) rotateZ(-30deg);
      }

      &.badge--rotate-right {
        transform: translateY(-50%) translateX(-110%) rotateZ(30deg);
      }
    }
  }

  &-row--bottom-left .badge {
    @media (min-width: map-get($grid-breakpoints, xl)+400px) {
      transform: translateX(-110%);
      position: absolute;

      &.badge--rotate-left {
        transform: translateY(-60%) translateX(-110%) rotateZ(-30deg);
      }

      &.badge--rotate-right {
        transform: translateY(-60%) translateX(-110%) rotateZ(30deg);
      }
    }
  }

  &-row--xl-bottom-left .badge,
  &-row--xl-top-left .badge {
    @media (min-width: map-get($grid-breakpoints, xl)+400px) {
      align-self: flex-start;
      transform: translateY(-50%) translateX(-110%);
      position: absolute;

      &.badge--rotate-left {
        transform: translateY(-50%) translateX(-110%) rotateZ(-30deg);
      }

      &.badge--rotate-right {
        transform: translateY(-50%) translateX(-110%) rotateZ(30deg);
      }
    }
  }

  &-row--xl-bottom-right .badge,
  &-row--xl-top-right .badge {

    @media (min-width: map-get($grid-breakpoints, xl)+400px) {
      align-self: flex-end;
      transform: translateY(-60%) translateX(100%);
      position: absolute;

      &.badge--rotate-left {
        transform: translateY(-60%) translateX(100%) rotateZ(-30deg);
      }

      &.badge--rotate-right {
        transform: translateY(-60%) translateX(100%) rotateZ(30deg);
      }
    }
  }

  &-row--xl-bottom-center .badge,
  &-row--xl-top-center .badge {
    @media (min-width: map-get($grid-breakpoints, xl)) {
      align-self: center;

      &.badge--rotate-left {
        transform: rotateZ(-30deg);
      }

      &.badge--rotate-right {
        transform: rotateZ(30deg);
      }
    }

  }
}

.share {
  &_icon {
    display: inline-block;
    height: rem($share-icon-size);
    width: rem($share-icon-size);
    background-size: contain;
    transition: 0.3s all ease-in-out;
    background-repeat: no-repeat;
    &:hover {
      opacity: .7;
    }
  }

  @each $item in $socialIcons {
    &_#{$item} {
      .stage--withimage & {
        @extend %icon-#{$item}--white;
      }

      &:hover , .bg-dark &:hover {
        @extend %icon-#{$item}--cyan;
        opacity: 1;
      }

      @extend %icon-#{$item}--black;
    }
  }
}
