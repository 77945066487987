.divider {
    @extend .py-4;
    min-height: 50vw;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(xs) {
        [class*="badge-row--xs-top"] {
            order: 1;
        }
        [class*="badge-row--xs-bottom"] {
            order: 2;
        }
    }
    @include media-breakpoint-up(sm) {
        font-size: $parallax-divider-font-size * 3;

        [class*="badge-row--sm-top"] {
            order: 1;
        }
        [class*="badge-row--sm-bottom"] {
            order: 2;
        }
    }
    @include media-breakpoint-up(md) {
        font-size: $parallax-divider-font-size * 4;

        [class*="badge-row--md-top"] {
            order: 1;
        }
        [class*="badge-row--md-bottom"] {
            order: 2;
        }
    }
    @include media-breakpoint-up(lg) {
        font-size: $parallax-divider-font-size * 5;

        [class*="badge-row--lg-top"] {
            order: 1;
        }
        [class*="badge-row--lg-bottom"] {
            order: 2;
        }
    }
    @include media-breakpoint-up(xl) {
        // height: 50vw;
        [class*="badge-row--xl-top"] {
            order: 1;
        }
        [class*="badge-row--xl-bottom"] {
            order: 2;
        }
    }

    &--black {
        color: $black;
        &:hover {
            color: $black;
        }
    }
    &--white {
        color: $white;
        &:hover {
            color: $white;
        }
    }

    &__container {
        height: 100%;
        position: relative;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 100%;

        > * {
            order: 1;
        }
    }

    &__text {
        &--left {
            text-align: left;
        }
        &--center {
            text-align: center;
        }
        &--right {
            text-align: right;
        }
    }

    &__button {
        @extend .my-3;

        &--left {
            align-self: flex-start;
        }
        &--center {
            align-self: center;
        }
        &--right {
            align-self: flex-end;
        }
    }
    .parallax {
        &-wrapper {
            clip: rect(0, auto, auto, 0);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -3;
        }
        &-image {
            position: fixed;
            display: block;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background-size: cover;
            background-position: center center;
            transform: translate(0);
            z-index: -10;
            will-change: transform;
            display: none;

            &--sm {
                @include media-breakpoint-down(sm) {
                    display: block;
                }
            }
            &--md {
                @include media-breakpoint-between(md, md) {
                    display: block;
                }
            }
            &--lg {
                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }
        }
    }
}
