.section-teaser__image {
    background-size:cover;
    background-position: center center;
    min-height: $sectionteaser-height;

    @include media-breakpoint-up(sm){
        min-height: rem($sectionteaser-image-height-sm); // 4:3 format
    }
    @include media-breakpoint-up(md){
        min-height: rem($sectionteaser-image-height-md); // 4:3 format
    }
    @include media-breakpoint-up(lg){
        min-height: rem($sectionteaser-image-height-lg); // 3:4 format
    }
    @include media-breakpoint-up(xl){
        min-height: rem($sectionteaser-image-height-xl); // 3:4 format
    }

    .section-teaser--image-left & {
        @extend .col-12;
        @extend .col-lg-5;
        @extend .order-lg-1;
    }
    .section-teaser--image-right & {
        @extend .col-12;
        @extend .col-lg-5;
        @extend .order-lg-2;
        @extend .offset-lg-1;
    }

    .section-teaser--fullwidth & {
        width: 100%;
        height: $sectionteaser-height;
        min-height: 0;
        min-width: 100vw;

        @include media-breakpoint-up(sm) {
            --containermax: #{map-get($container-max-widths, 'sm')};
            --spaceleft: calc(100vw - var(--containermax));
            margin-left: calc(var(--spaceleft) / -2);
            margin-right: calc(var(--spaceleft) / -2);

        }

        @include media-breakpoint-up(md) {
            --containermax: #{map-get($container-max-widths, 'md')};
            --spaceleft: calc(100vw - var(--containermax));
            margin-left: calc(var(--spaceleft) / -2);
            margin-right: calc(var(--spaceleft) / -2);

        }

    }

    .section-teaser--fullwidth-image-right.section-teaser--fullwidth & {
        @extend .col-12;
        @extend .col-lg-5;
        @extend .order-lg-2;
        padding-left: 0!important;
        padding-right: 0!important;
        @include media-breakpoint-up(lg) {
            position: absolute !important;
            left: 50%;
            width: 50vw;
            min-width: 50vw;
            height: 100%;
            margin: 0;
        }
    }

    .section-teaser--fullwidth-image-left.section-teaser--fullwidth & {
        @extend .col-12;
        @extend .col-lg-5;
        padding-left: 0 !important;
        padding-right: 0!important;
        @include media-breakpoint-up(lg) {
            position: absolute !important;
            right: 50%;
            width: 50vw;
            min-width: 50vw;
            height: 100%;
            margin: 0;
        }
    }
}
.section-teaser__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding-top: rem($font-size-base*2);
    padding-bottom: rem($font-size-base*2);

    .bg-dark & {
        color: $white;
    }

    .section-teaser--image-left & {
        @extend .col-12;
        @extend .col-lg-5;
        @extend .order-lg-1;
        @extend .offset-lg-1;
    }
    .section-teaser--image-right & {
        @extend .col-12;
        @extend .col-lg-6;
        @extend .order-lg-1;
    }

    .section-teaser--fullwidth & {
        @include media-breakpoint-up(lg) {
            padding-top: rem($font-size-base*4);
            padding-bottom: rem($font-size-base*4);
        }
        .bg-dark &{
            color: $white;
        }
    }

    .section-teaser--fullwidth.section-teaser--fullwidth-image-right & {
        @extend .col-12;
        @extend .col-lg-6;
        @extend .order-lg-1;
        @include media-breakpoint-up(lg) {
          padding-right: 3rem !important;
        }
    }
    .section-teaser--fullwidth.section-teaser--fullwidth-image-left & {
        @extend .col-12;
        @extend .col-lg-6;
        @extend .order-lg-1;
        @extend .offset-lg-6;
        @include media-breakpoint-up(lg) {
          padding-left: 3rem !important;
      }
    }

    .section-teaser--plain-left & {
        @extend .py-0;
        padding-right: $sectionteaser-container-padding;
        padding-left: $sectionteaser-container-padding;
    }
    .section-teaser--plain-right & {
        @extend .py-0;
        padding-right: $sectionteaser-container-padding;
        padding-left: $sectionteaser-container-padding;
    }
}
.section-teaser-wrapper {
    &__heading {
        .section-teaser--plain-left & {
            @extend .col-lg-6;
            @extend .order-lg-1;
        }
        .section-teaser--plain-right & {
            @extend .col-lg-6;
            @extend .order-lg-2;
        }
        .bg-dark & {
            color: $white;
        }
    }
    &__text {
        .section-teaser--plain-left & {
            @extend.col-lg-6;
            @extend .order-lg-2;
        }
        .section-teaser--plain-right & {
            @extend .col-lg-6;
            @extend .order-lg-1;
        }
        .bg-dark & {
            color: $white;
        }
    }
}
.section-teaser__subline {
    font-weight: $stage-subline-fontweight;
    font-family: $font-family-sans-serif;
    line-height: $textimage-lineheight;

    .section-teaser--plain-left & ,
    .section-teaser--plain-right & {
        @extend %section-teaser-plain__subline;
    }
}

.section-teaser__text {
    font-weight: $font-weight-light;
    font-size: rem($sectionteaser-fontsize);
    line-height: $sectionteaser-text-lineheight;
    margin-bottom: 0;

    @include media-breakpoint-up(lg){
        width: 80%;
    }

    + .btn {
        margin-top: rem($sectionteaser-text-btn-space);
    }

    .section-teaser--plain-left & ,
    .section-teaser--plain-right & {
        @extend %section-teaser-plain__text;
    }
}
.section-teaser--fullwidth {
    overflow: hidden;

    .row {
        position: relative;
        align-items: stretch;

        @include media-breakpoint-up(lg) {
            flex-wrap: nowrap;
        }
    }
}
%section-teaser-plain__subline {
    @extend .mb-3;
    font-size: rem($h3-font-size);
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-normal;
}
%section-teaser-plain__text {
    @extend .mb-3;
    margin-bottom: 0;
    font-weight: $font-weight-light;
    font-size: rem($text-font-size);
    line-height: $sectionteaser-text-lineheight;

    @include media-breakpoint-up(lg) {
        line-height: $sectionteaser-text-lineheight-lg;
    }
}
.section-teaser .badge-row {
    @include media-breakpoint-down(lg){
        overflow: hidden;
    }
}
.section-teaser .badge-row .badge {
    @include media-breakpoint-up(lg){
        position: relative;
    }
}
