.slider {
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;

  @extend .py-3;

  ul {
    list-style: none;
    position: relative;
  }

  .hide {
    display: none;
  }
}

.slider__elements {
  transition: all .5s ease-out;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  padding: 0;
  margin: 0;

  max-height: rem($slider-maxheight);
}

.slider__element {
  flex-shrink: 0;
  flex-grow: 0;

  height: rem($slider-maxheight);
  max-width: 100%;

  margin: 0 0.5rem;
  transition: transform .5s ease-in-out;
  transform-origin: center;

  &:first-child {
    transform-origin: center left;
    margin-left: 0;
  }

  &:last-child {
    transform-origin: center right;
    margin-right: 0;
  }

  // achtung, folgender style kann sich verändern und ist separat definiert
  // flex-basis: ?px;

  &:hover {
    @include media-breakpoint-up(lg) {
      transform: scale(1.1);
      z-index: 10;
    }
  }

  &.active {
    .slide__image {
      box-shadow: 0px 15px 25px -10px rgba(96, 125, 139, 0.5);
    }

    .slide__caption {
      background: rgba(0, 0, 0, 0.125);

      *:not(a) {
        text-shadow: 0px 0px 50px $black;
      }
    }

    .slide__caption-text {
      opacity: 1;
    }
  }

  .slide__image {
    height: 100%;
    transition: all .5s ease-in-out;
  }

  .slide {
    height: 100%;
    display: block;
    position: relative;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  .slide__caption {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: visible;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: $spacer * 1.5;
    transition: all .5s ease-in-out;
    background: rgba(0, 0, 0, 0.5);
    font-size: rem(14);

    .slider--contacts & {
      position: relative;
    }
  }

  .slide__caption-social {
    display: flex;
    margin-bottom: 16px;

    > * {
      height: 32px;
      flex: 0 0 32px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 8px;
      transition: 0.3s all ease-in-out;

      &:hover {
        opacity: .7;
      }
    }

    @each $item in $socialIcons {
      .slide__caption-#{$item} {
          @extend %icon-#{$item}--black;

          &:hover , .bg-dark &:hover {
            @extend %icon-#{$item}--cyan;
            opacity: 1;
          }

          .bg-dark & {
            @extend %icon-#{$item}--white;
          }
      }
    }
  }

  .slide__caption-category {
    text-transform: uppercase;
  }

  .slide__caption-headline {
    font-weight: bold;
    font-size: rem($slider-captionheadline-fontsize);
    line-height: rem($slider-captionheadline-lineheight);
  }

  .slide__caption-link {

    &--wrapper {
      position: absolute;
      bottom: $spacer*2.5;
      right: $spacer*1.5;
    }
  }
  .slide__caption-text {
    flex: 1;

    line-height: 1.44;
    font-size: 11pt;
    overflow: hidden;

    transition: opacity .5s ease-in-out;
    opacity: 0;

    &:before {
      content: "";
      display: block;
      width: $slider-captiondivider-width;

      margin: rem($slider-captiondivider-verticalmargin) 0;
      border: rem($slider-captiondivider-borderwidth) solid $white;
    }
  }

  .slide__caption-category,
  .slide__caption-headline,
  .slide__caption-text {
    color: $white;
  }
}

.slider__pagination {
  padding: 0;
  margin-bottom: 0;

  a {
    @extend .btn;
    //@extend .btn--transparent;
  }

  .active a {
    @extend %btn-hover;
  }
}

.slider__pagination,
.slider__controls {
  font-size: rem($slider-pagination-fontsize);
  line-height: rem($slider-pagination-lineheight);

  display: flex;

  li {
    display: inline-block;
    margin-right: rem($btn-padding-x-sm);
  }

  a {
    font-size: rem($slider-pagination-fontsize);
    text-decoration: none;
    padding: rem($btn-padding-x-sm);
  }
}

.slider__controls {
  overflow: hidden;
}

.slider__control--current {
  font-weight: bold;
}

.slider__control--divider {
  margin: 0 rem(10);
}

.slider__control--prev,
.slider__control--next {
  @extend %icon-arrow-small--black;

  background-repeat: no-repeat;
  background-size: $slider-controlbtn-bgsize;
  background-position: center;
  text-indent: -999px;
  width: rem($slider-controlbtn-size);
  height: rem($slider-controlbtn-size);
  display: inline-block;

  .bg-dark & {
    @extend %icon-arrow-small--white;
  }
}

.slider__control--prev {
  transform: rotate(180deg);
}

.slider__footer {
  @extend .pt-3;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;

  .bg-dark & {
    color: $white;
  }

  &--allLinks{
    line-height: 21px;
  }

  &--flex-reverse{
    flex-direction: row-reverse;
  }
}



// contact slider

.slider--contacts {
  .slider__elements {
    max-height: none;
  }

  .slider__element {
    height: auto;
    width: rem($slidercontacts-itemwidth);

    &.active {
      .slide__caption * {
        text-shadow: none;
      }

      .slide__image {
        opacity: 1;
        box-shadow: 0px 15px 25px -10px rgba(96, 125, 139, 0.5);
      }
    }

    &:hover {
      transform: scale(1);

      .slide__image {
        opacity: 1;
      }

      .slide__caption * {
        text-shadow: none;
      }
    }

    .slide__image {
      max-width: 100%;
      height: auto;
      opacity: 0.5;
    }

    .slide__caption {
      padding: 1rem;
      background: transparent;
    }

    .slide__caption-category,
    .slide__caption-headline,
    .slide__caption-text {
      color: $black;

      .bg-dark & {
        color: $white;
      }
    }

    .slide__caption-text {
      opacity: 1;
      @extend .py-3;

      &:before {
        display: none;
      }
    }

    .slide__caption-category {
      text-transform: none;
    }

    .slide__caption-phone,
    .slide__caption-mail {
      display: inline-block;
      color: $black;
      border-bottom: rem(2) solid;
      font-size: rem($slider-captionheadline-fontsize);
      margin-bottom: $spacer / 2;
      @include media-breakpoint-up(md) {
        font-size: rem($slidercontacts-contactdetails-fontsize);
      }

      &:hover {
        color: $link-hover-color;
      }

      .bg-dark & {
        color: $white;

        &:hover {
          color: $link-hover-color;
        }
      }
    }
  }

  &.no-highlight {
    .slider__element {
      .slide__image {
        opacity: 1;
        box-shadow: 0px 15px 25px -10px rgba(96, 125, 139, 0.5);
      }
    }
  }
}

.slider--wrapper {
  p {
    font-weight: $font-weight-lighter;
  }
  > h2 {
    @extend .mb-3;
  }
}
