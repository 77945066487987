// imports
@import 'bootstrap/functions';
@import 'bootstrap/variables';

@import '../svg-css/icons';
@import 'variables';

@import 'bootstrap/mixins/deprecate';
@import 'bootstrap/mixins/breakpoints';
@import 'bootstrap/vendor/rfs';
@import 'bootstrap/mixins/hover';
@import 'bootstrap/mixins/lists';
@import 'bootstrap/mixins/border-radius';
@import 'bootstrap/mixins/box-shadow';
@import 'bootstrap/mixins/transition';
@import 'bootstrap/mixins/gradients';
@import 'bootstrap/utilities/flex';
@import 'bootstrap/reboot';

@import 'bootstrap/media';

// rem function
@function rem($pixel) {
  @if (unitless($pixel)) {
    $pixel: $pixel * 1px;
  }
  @return $pixel / 16px * 1rem;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

picture {
  display: block;
}
