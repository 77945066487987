// colors
$black: rgba(0,0,0,1);
$white: rgba(255,255,255,1);
$blue: rgba(65,99,255,1);
$red: rgba(255,65,65,1);
$yellow: rgba(255,213,65,1);
$green: rgba(65,255,84,1);
$grey: lighten($black, 25);
$button-grey: rgba(51,51,51,1);
$dark-red: darken($red, 15);
$dark-yellow: darken($yellow, 15);
$dark-green: darken($green, 15);
$dark-blue: darken($blue, 15);
$transparent: rgba(0,0,0,0);
$cyan: rgba(0,150,191,1);

// fonts / text

@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/OpenSans/OpenSans-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/OpenSans/OpenSans-BoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/OpenSans/OpenSans-LightItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

$font-family-sans-serif: 'OpenSans', sans-serif;

$font-weight-light: 100;
$font-weight-bolder: 800;

$text-color:        $black;
$text-font-size:    18px;
$font-size-base:    18px;

$headings-font-family: $font-family-sans-serif;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1;
$h1-font-size: 80px;
$h2-font-size: 40px;
$h3-font-size: 24px;
$h4-font-size: 18px;
$h5-font-size: 18px;
$h6-font-size: 18px;

// text-image-module
$textimage-lineheight: 1.75;
$textimage-fontweight: 100;
$textimage-h3-lineheight: 1.5;
$textimage-figdescription-fontsize: 0.75em;
$textimage-figdescription-mgtop: 0.5em;
$textimage-fig-bgcolor: $grey;
$subline-fontsize: 24px;
$subline-lineheight: 28px;

// icons

$socialIcons: (
  'twitter',
  'linkedin',
  'facebook',
  'instagram',
  'xing'
);

// grid
$grid-columns: 12;
$grid-gutter-width: 30px;
$grid-gutter-width-half: $grid-gutter-width /2;

// buttons
$btn-text-color:        $button-grey;
$btn-font-size:         18px;
$btn-font-family:       $font-family-sans-serif;
$btn-font-weight:       $font-weight-bolder;

$btn-border-width:      1px;
$btn-border-color:      transparent;

$btn-text-color-hover:  $white;
$btn-bgcolor-hover:     $black;

//padding for buttons
$btn-padding-x:     18px;
$btn-padding-y:     14px;
$btn-padding-x-sm: $btn-padding-x*0.5;
$btn-padding-y-sm: $btn-padding-y*0.5;
$btn-padding-x-lg: $btn-padding-x*1.5;
$btn-padding-y-lg: $btn-padding-y*1.5;

//button arrow
$btn-arrow-padding-medium-y: 6px;
$btn-arrow-padding-medium-x: 9px;
$btn-arrow-padding-large-y: 11px;
$btn-arrow-padding-large-x: 15px;
$btn-arrow-padding-left: 20px;
$btn-arrow-after-margin: 20px;

// button arrow-only
$btn-arrow-only-padding-left: 12px;
$btn-arrow-only-after-hover-margin: 5px;

//social media buttons
$social-media-button-bg-color:  transparent;
$social-media-button-height:    35px;
$social-media-button-width:     $social-media-button-height;

// submit button
$btn-submit-font-size: 12;
$btn-submit-letter-spacing: 1.5;
$btn-submit-icon-margin-left: 5px;

$linkArrowLeft-height:  40px;
$linkArrowLeft-width:   40px;

$linkArrowRight-height:  40px;
$linkArrowRight-width:   40px;

// links
$link-color:            $black;
$link-text-font-size:   14px;
$link-decoration:       none;
$link-hover-color:      $cyan;
$link-hover-decoration: none;
$link-border-bottom:    3px;
$link-arrow-padding: 20px;

$link-vertical-font-size: $link-text-font-size;

$share-icon-size: 40px;

// footer
$footer-pt:     $spacer * 3;
$footer-pb:     $spacer * 0.5;
$footer-pl:     $spacer;
$footer-pr:     $footer-pl;
$footer-pl-md:  $spacer * 4.5;
$footer-pr-md:  $footer-pl-md;

$footer-bgcolor:        $black;
$footer-textcolor:      $gray-500;
$footer-headlinecolor:  $white;
$footer-fontsize:       12px;
$footer-fontfamily:     $font-family-sans-serif;

$footer-logo-height: 35px;
$footer-logo-bgwidth: 160px;
$footer-logo-mb: $spacer * 1.5;

$footer-claim-mb: $spacer * 3;

$footer-nav-mb:         $spacer * 1.5;
$footer-navitem-mb:     $spacer * 0.5;

$footer-naviheadline-mb:            $spacer;
$footer-naviheadline-fontweight:    $font-weight-bold;
$footer-naviheadline-textcolor:     $footer-headlinecolor;

$footer-link-color: $gray-500;

$footer-newsletter-mb: $spacer * 3;

$footer-newsletterlabel-mb: $spacer * 0.25;

$footer-newsletterinput-borderbottomwidth: 1px;
$footer-newsletterinput-pt:         25px;
$footer-newsletterinput-pr:         50px;
$footer-newsletterinput-pb:         5px;
$footer-newsletterinput-pl:         5px;
$footer-newsletterinput-pr-lg:      0;
$footer-newsletterinput-pt-lg:      15px;
$footer-newsletterinput-bgcolor:    $footer-bgcolor;
$footer-newsletterinput-textcolor:  $white;

$footer-newsletterbutton-bgcolor:   $footer-bgcolor;
$footer-newsletterbutton-textcolor: $white;
$footer-newsletterbutton-pt:        25px;
$footer-newsletterbutton-pr:        5px;
$footer-newsletterbutton-pb:        5px;
$footer-newsletterbutton-pl:        5px;
$footer-newsletterbutton-pt-lg:     15px;
$footer-newsletterbutton-pr-lg:     0px;
$footer-newsletterbutton-pb-lg:     15px;
$footer-newsletterbutton-pl-lg:     15px;
$footer-newsletterbutton-iconheight: 36px;
$footer-newsletterbutton-iconwidth: 36px;


// stage
$stage-padding-top: 160px;
$stage-padding-bottom: 80px;
$stage-links-margin-bottom: 40px;
$stage-headline-fontsize: 72px;
$stage-headline-lineheight: 1.1;
$stage-headline-margin-top: $text-font-size;
$stage-headline-margin-bottom: $text-font-size*2;
$stage-intro-lineheight: 2;
$stage-intro-fontsize: $text-font-size;
$stage-subline-lineheight: 1;
$stage-subline-fontsize: $h4-font-size;
$stage-subline-fontweight: 400;

$stage-badgesize-small: 100px;
$stage-badgesize-medium: 150px;
$stage-badgesize-large: 200px;

$badge-rotation: 30deg;

// forms
$form-textcolor: $black;
$darkform-textcolor: $white;

$form-fontsize: $font-size-base;

$input-label-fontsize: $font-size-base;
$input-label-fontfamily: $font-family-sans-serif;
$input-label-fontweight: $font-weight-normal;
$input-label-lineheight: 1.5;

$input-compact-borderwidth: 1px;
$input-compact-bordercolor: $black;
$input-compact-bordercolorinverted: $white;

$input-font-size: $font-size-base;
$input-bg: $white;
$input-color: $black;
$input-border-color: $black;
$input-border-radius: 0;

//eigene Variablen für forms
$input-bg-inverted: $black;
$input-color-inverted: $white;
$input-border-color-inverted: $white;

$input-checkbox-padding-left: 20px;
$input-checkbox-position-left: 8px;

//form validation
$form-feedback-valid-color: darken($green, 25%);
$form-feedback-invalid-color: $red;

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-invlaid-color: $form-feedback-invalid-color;

//eigene Variablen für Form validation
$form-input-group-prepend-valid-padding: $input-padding-x;
$form-input-group-prepend-invalid-padding: $form-input-group-prepend-valid-padding;

//On Page Navi
$onpagenavi-height: 10px;
$onpagenavi-bgcolor: $white;

$onpagenavi-circle-color: $black;
$onpagenavi-circle-width: 10px;
$onpagenavi-circle-height: $onpagenavi-circle-width;
$onpagenavi-circle-borderwidth: 1px;

$onpagenavi-semifilledcircle-width: 1px;
$onpagenavi-semifilledcircle-height: $onpagenavi-semifilledcircle-width;

$onpagenavi-link-hoverheight: 27px;
$onpagenavi-link-hormovement: 200px;
$onpagenavi-link-color: $text-color;
$onpagenavi-link-fontfamily: $font-family-sans-serif;
$onpagenavi-link-fontsize: $font-size-base;
$onpagenavi-link-fontweight: $font-weight-normal;
$onpagenavi-link-hoverfontweight: $font-weight-bold;
$onpagenavi-link-borderwidth: 3px;

$onpagenavi-item-height: 27px;
$onpagenavi-item-width: 46px;
$onpagenavi-item-hoverwidth: 180px;

$onpagenavi-point-hormargin: 16px;

// Section teaser
$sectionteaser-fontsize: $font-size-base;
$sectionteaser-height: 75vw;
$sectionteaser-container-padding: 15px;
$sectionteaser-image-height-sm: 405px;
$sectionteaser-image-height-md: 540px;
$sectionteaser-image-height-lg: 533px;
$sectionteaser-image-height-xl: 633px;
$sectionteaser-text-btn-space: 32px;
$sectionteaser-text-lineheight: 1.5;
$sectionteaser-text-lineheight-lg: 2;

// Main-nav
$mainnav-fontfamily: $font-family-sans-serif;
$mainnav-textcolor: $white;
$mainnav-bgcolor: $black;

$mainnav-header-padding-y: 10px;
$mainnav-header-padding-x: $mainnav-header-padding-y*1.5;

$mainnav-body-padding-top:      100px;
$mainnav-body-padding-top-md:   175px;

$mainnav-search-input-padding-top: 15px;
$mainnav-search-input-textcolor: $white;

$mainnav-contact-mt: 100px;
$mainnav-contact-mb: 20px;
$mainnav-contact-maxwidth: 80%;
$mainnav-contacttext-mb: $spacer * 0.5;
$mainnav-contacttext-fontsize: 14px;
$mainnav-contacticons-width: 20px;
$mainnav-contacticons-height: 20px;
$mainnav-contacticons-postop: 4px;
$mainnav-contacticons-mr: 8px;

// Main-nav menu
$mainnav-navlink-font-size: 20px;
$mainnav-navlink-pb: $spacer * 0.75;
$mainnav-navlink-pt: $mainnav-navlink-pb;
$mainnav-navlink-iconwidth: 24px;
$mainnav-navlink-iconheight: $mainnav-navlink-iconwidth;
$mainnav-navlink-icon-ml: 12px;
$mainnav-navlink-iconback-mr: 5px;

// Main-nav logo & icon/buttons
$mainnav-logo-width: 160px;
$mainnav-logo-height: 50px;
$mainnav-icon-width: 50px;
$mainnav-icon-height: 5px;
$mainnav-icon-margin-left: 12px;
$mainnav-icon-color: $white;
$mainnav-icon-barspace: 15px;

$mainnav-labelOpen-left: 40px;
$mainnav-labelOpen-padding-x: 5px;

$mainnav-labelClose-padding-top: 5px;
$mainnav-labelClose-padding-right: 0px;
$mainnav-labelClose-padding-bottom: 5px;
$mainnav-labelClose-padding-left: 15px;

// Quote
$quote-lineheight: 2;
$quote-text-fontfamily: $font-family-sans-serif;
$quote-text-fontweight: $font-weight-light;
$quote-text-marginbottom: 30px;
$quote-author-fontweight: $font-weight-bold;

// Parallax-Divider
$parallax-divider-font-size: 8px;

$divider-badgesize-small: 100px;
$divider-badgesize-medium: 150px;
$divider-badgesize-large: 200px;

// Slider
$slider-maxheight: 300px;
$slider-captionheadline-fontsize: 16px;
$slider-captionheadline-lineheight: 20px;
$slider-captiondivider-width: 25%;
$slider-captiondivider-verticalmargin: 8px;
$slider-captiondivider-borderwidth: 2px;
$slider-pagination-fontsize: 14px;
$slider-pagination-lineheight: 35px;
$slider-controlbtn-bgsize: 30px 30px;
$slider-controlbtn-size: 35px;
$slider-alllink-fontsize: $slider-pagination-fontsize;
$slider-alllink-lineheight: $slider-pagination-lineheight;
$slider-alllink-arrowwidth: 14px;
$slider-alllink-arrowheight: 12px;
$slider-alllink-postop: 3px;
$slider-alllink-bgsize: 35px;
$slider-alllink-mgleft: 20px;
$slidercontacts-itemwidth: 300px;
$slidercontacts-contactdetails-fontsize: 12px;

%btn-hover {
  background-color: $btn-bgcolor-hover;
  color: $btn-text-color-hover;
}

// tiles / kacheln
$tiles-caption-bgcolor: rgba(0,0,0,0.5);
$tiles-caption-bgcolor-hover: rgba(0,0,0,0.25);
$tiles-caption-fontsize: 14px;
$tiles-captionheadline-fontsize: 16px;
$tiles-captionheadline-lineheight: 20px;

// search
$search-stage-height: 50vh;
$search-stage-minheight: 300px;

$search-forminput-height: 60px;
$search-forminput-paddingright: 65px;
$search-forminput-lineheight: 1;
$search-forminput-borderwidth: 1px;
$search-formbtn-width: 60px;
$search-formbtn-height: 60px;

$searchresult-image-width: 100px;
$searchresult-breadcrumb-fontsize: 12px;
$searchresult-breadcrumb-color: darken($green, 25);
$searchresult-title-fontsize: 20;
$searchresult-title-fontweight: $font-weight-bold;
$searchresult-text-fontsize: 16px;

