.footer {
    padding-top: $footer-pt;
    padding-bottom: $footer-pb;
    padding-left: $footer-pl;
    padding-right: $footer-pr;

    background-color: $footer-bgcolor;

    font-family: $footer-fontfamily;
    color: $footer-textcolor;
    font-size: rem($footer-fontsize);

    @include media-breakpoint-up(md){
      font-size: rem($footer-fontsize);
      padding-left: $footer-pl-md;
      padding-right: $footer-pr-md;
    }


}



.footer__logo {
    @extend %icon-comspace-logo--white;

    margin-bottom: $footer-logo-mb;
    height: rem($footer-logo-height);
    background-repeat: no-repeat;
    background-size:  rem($footer-logo-bgwidth) auto;
    background-position: left center;
}

.footer__claim {
    margin-bottom: $footer-claim-mb;
}

.footer__nav {
    margin-bottom: $footer-nav-mb;
    font-size: rem($text-font-size);

    @include media-breakpoint-up(md){
      font-size: rem($footer-fontsize);
    }

    &__linklist {
        @extend .list-unstyled;

        li {
            margin-bottom: $footer-navitem-mb;
        }
    }
}

.footer__nav__headline {
    margin-bottom: $footer-naviheadline-mb;

    font-weight: $footer-naviheadline-fontweight;
    color: $footer-naviheadline-textcolor;
}

.footer__link:link,
.footer__link:visited {
    text-decoration: none;
    color:  $footer-link-color;
}
.footer__link:hover,
.footer__link:active {
    text-decoration: underline;
}


.footer__newsletter {
    margin-bottom: $footer-newsletter-mb;
    position: relative;

    label {
        @extend .footer__nav__headline;
        margin-bottom: $footer-newsletterlabel-mb;
    }
    input[type=text]{
        width: 100%;
        border: none;
        border-bottom: rem($footer-newsletterinput-borderbottomwidth) solid $white;
        outline: none;
        padding: rem($footer-newsletterinput-pt) rem($footer-newsletterinput-pr) rem($footer-newsletterinput-pb) rem($footer-newsletterinput-pl);

        background-color: $footer-newsletterinput-bgcolor;
        color: $footer-newsletterinput-textcolor;

        @include media-breakpoint-up(lg){
            padding-right: rem($footer-newsletterinput-pr-lg);
            padding-top: rem($footer-newsletterinput-pt-lg);
        }
    }

    & .btn {
      margin-top: rem(16px);
      float: right;
    }
}

.footer__newsletter__formfield{
    position: relative;
}

.footer__legal {
    @extend .text-center;
    @extend .text-md-left;
}
