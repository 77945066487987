//imports
@import "bootstrap/utilities/display";

picture {
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
