.search__stage {
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-position: center center;
    background-size: cover;

    height: $search-stage-height;
    min-height: rem($search-stage-minheight);
    position: relative;
    > picture {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
    }

    &__form {
        color: $white;

        .row {
            position: relative;
        }
    }
    &__form-input {
        display: block;
        width: 100%;
        height: rem($search-forminput-height);
        border: none;
        border-bottom: $search-forminput-borderwidth solid $white;
        padding-left: 0;
        padding-right: rem($search-forminput-paddingright);
        overflow: hidden;

        background-color: transparent;
        color: $white;
        font-size: $h2-font-size;
        line-height: $search-forminput-lineheight;

        /* clears the 'X' from Internet Explorer */
        &::-ms-clear {  display: none; width : 0; height: 0; }
        &::-ms-reveal {  display: none; width : 0; height: 0; }

        /* clears the 'X' from Chrome */
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration { display: none; }

        &:focus {
            outline: none;
        }
    }
    &__form-btn {
        @extend %icon-search--white;

        position: absolute;
        right: 0;
        top: 0;

        width: rem($search-formbtn-width);
        height: rem($search-formbtn-height);

        display: block;
        overflow: hidden;
        border: none;

        text-indent: -999px;
        background-color: transparent;
        background-size: contain;
        background-position: center center;

        &:focus {
            outline: none;
        }
    }
}
.search__results {
    @extend .py-5;

    &-pagination {
        @extend .slider__pagination;
    }
}
.search__results-list {
    @extend .mt-5;
    padding: 0;
}
.search__result-item {
    @extend .media;
    @extend .mb-md-4;
    @extend .mb-5;

    &__image {
        @extend .d-none;
        @extend .d-md-block;
        @extend .mr-md-3;
        width: rem($searchresult-image-width);
        box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.2);
    }
    &__breadcrumb {
        letter-spacing: 1px;
        font-size: rem($searchresult-breadcrumb-fontsize);
        color: $searchresult-breadcrumb-color;
        span {
          &:after {
            content: ' > ';
          }
          &:last-child {
            &:after {
              content: '';
            }
          }
        }
        a {
          color: $searchresult-breadcrumb-color;

          &:hover {
            opacity: 0.8;
          }
        }
    }
    &__title {
        @extend .mb-1;
        font-weight: $searchresult-title-fontweight;
        font-size: rem($searchresult-title-fontsize);
        display: block;
    }
    &__text {
        display: block;
        font-size: rem($searchresult-text-fontsize);
    }

    &:hover a {
      color: $cyan;
    }
}
