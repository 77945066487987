.blockqoute {
    @extend .font-italic;

    text-align: center;
    line-height: $quote-lineheight;
    
    .bg-dark & {
        color: $white;
    }

    &__text {
        font-family: $quote-text-fontfamily;
        font-weight: $quote-text-fontweight;
        margin-bottom: rem($quote-text-marginbottom);
    }    
}
.blockquote-footer {
    text-align: center;
    color: $black;
    
    &:before {
        content: '';
        display: none;
    }

    .bg-dark & {
        color: $white;
    }

    &__source {
        &-author {
            font-weight: $quote-author-fontweight;
        }
        &-author,
        &-position,
        &-company {
            color: $black;
            
            .bg-dark &{
                color: $white;
            }
        }
    }
}