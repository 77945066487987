//imports
@import 'bootstrap/mixins/text-hide';
@import 'bootstrap/type';
//@import "bootstrap/mixins/hover";
@import 'bootstrap/mixins/text-emphasis';
@import 'bootstrap/mixins/text-truncate';

//@import "bootstrap/mixins/lists";

@import 'bootstrap/utilities/text';

body {
  font-family: $font-family-sans-serif;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  word-wrap: break-word;
  hyphens: auto;
  overflow-wrap: break-word;
}

h1 {
  font-size: $h1-font-size * 0.5;

  @include media-breakpoint-up(md) {
    font-size: $h1-font-size * 0.75;
  }

  @include media-breakpoint-up(lg) {
    font-size: $h1-font-size;
  }
}

h2 {
  font-size: $h2-font-size * 0.5;

  @include media-breakpoint-up(md) {
    font-size: $h2-font-size * 0.75;
  }

  @include media-breakpoint-up(lg) {
    font-size: $h2-font-size;
  }
}

h3 {
  font-size: $h3-font-size * 0.75;

  @include media-breakpoint-up(lg) {
    font-size: $h3-font-size;
  }
}

.subline {
  color: $gray-500;

  font-family: $font-family-sans-serif;
  font-weight: $font-weight-bold;
  font-size: rem($subline-fontsize);
  line-height: rem($subline-lineheight);
}

p a,
.link {
  font-weight: bold;
  border-bottom: $link-border-bottom solid $link-color;

  &:hover {
    color: $link-hover-color;
    border-bottom-color: $link-hover-color;
  }

  &:visited {
    color: $link-hover-color;
  }

  .bg-dark & {
    &,
    &:hover {
      color: $link-hover-color;
      border-bottom-color: $link-hover-color;
    }
    &:hover {
      opacity: 0.9;
    }
  }
}

div:not([class*='nav']) {
  > ul:not([class*='nav']),
  > ol {
    > li {
      > a {
        text-decoration: underline;
        font-weight: $font-weight-bolder;
      }
    }
  }
}
