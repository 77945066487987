.dotted-navbar {
  @include media-breakpoint-down(sm) {
    display: none;
  }

  display: flex;
  justify-content: center;
  flex-direction: column;

  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;

  height: 100%;

  box-shadow: -6px 1px 15px rgba(0, 0, 0, 0.125);
  background-color: $onpagenavi-bgcolor;

  &:hover {
    & .dotted-navbar__link {
      width: auto;
      height: rem($onpagenavi-link-hoverheight);

      transform: translateX(0px);
      transition: transform 0.5s ease-in-out 0.5s;
    }

    & .dotted-navbar__item {
      width: rem($onpagenavi-item-hoverwidth);
      transition: width 0.5s ease-in-out;

      $elements: 15;
      @for $i from 0 to $elements {
        &:nth-child(#{$i + 1}) .dotted-navbar__link {
          transition-delay: 0.5s + 0.1 * $i;
        }
      }
    }
  }

  &__list {
    list-style-type: none;
    text-align: right;
    padding-left: 0;
  }

  &__item {
    transition: width 0.5s ease-in-out 0.85s;
    width: rem($onpagenavi-item-width);
    height: rem($onpagenavi-item-height);
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    align-items: baseline;

    $elements: 15;
    @for $i from 0 to $elements {
      &:nth-last-child(#{$i + 1}) .dotted-navbar__link {
        transition-delay: 0s + 0.1s * $i;
      }
    }

    .dotted-navbar__link {
      &:hover {
        cursor: pointer;
        color: $onpagenavi-link-color;
        text-decoration: none;
        font-weight: $onpagenavi-link-hoverfontweight;
        border-bottom: $onpagenavi-link-borderwidth solid $black;
      }
    }
    &:hover {
      .dotted-navbar__point {
        cursor: pointer;
        @extend .circle--semi-filled;
      }
    }
  }

  &__link {
    display: inline-block;
    color: $onpagenavi-link-color;
    font-family: $onpagenavi-link-fontfamily;
    font-size: $onpagenavi-link-fontsize;
    font-weight: $onpagenavi-link-fontweight;
    border-bottom: $onpagenavi-link-borderwidth solid $onpagenavi-bgcolor;

    transition: transform 0.5s ease-in-out, width 0.5s ease-in-out;
    transform: translateX($onpagenavi-link-hormovement);
    vertical-align: middle;
  }

  &__point {
    @extend .circle;

    margin: 0 rem($onpagenavi-point-hormargin);
    display: inline-block;
    flex-shrink: 0;
  }
}

.active {
  .dotted-navbar__point {
    @extend .circle--filled;
  }
}

.circle {
  width: $onpagenavi-circle-width;
  height: $onpagenavi-circle-height;
  border: $onpagenavi-circle-borderwidth solid $onpagenavi-circle-color;
  border-radius: 50%;

  &--filled {
    background-color: $onpagenavi-circle-color;
  }

  &--semi-filled {
    position: relative;

    &:after {
      content: '';
      display: block;
      width: $onpagenavi-semifilledcircle-width;
      height: $onpagenavi-semifilledcircle-height;

      border: 1px solid $onpagenavi-circle-color;
      border-radius: 50%;
      background-color: $onpagenavi-circle-color;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
