.badge {
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  text-align: center;
  line-height: 1.1;

  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;

  &[style*=background-image] {
    border-radius: 0;
    background-color: transparent;
  }

  &-row {
    @extend .mb-3;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  &-row--top-left .badge {
    align-self: flex-start;

    &.badge--rotate-left {
      transform: rotateZ($badge-rotation *-1);
    }

    &.badge--rotate-right {
      transform: rotateZ($badge-rotation);
    }


  }

  &-row--top-right .badge {
    align-self: flex-end;

    &.badge--rotate-left {
      transform: rotateZ($badge-rotation *-1);
    }

    &.badge--rotate-right {
      transform: rotateZ($badge-rotation);
    }
  }

  &-row--top-center .badge {
    align-self: center;

    &.badge--rotate-left {
      transform: rotateZ($badge-rotation *-1);
    }

    &.badge--rotate-right {
      transform: rotateZ($badge-rotation);
    }
  }

  &-row--bottom-center {
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    .badge {

      &.badge--rotate-left {
        transform: rotateZ($badge-rotation *-1);
      }

      &.badge--rotate-right {
        transform: rotateZ($badge-rotation);
      }
    }
  }

  &-row--bottom-left {
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    .badge {

      &.badge--rotate-left {
        transform: rotateZ($badge-rotation *-1);
      }

      &.badge--rotate-right {
        transform: rotateZ($badge-rotation);
      }
    }

  }

  &-row--bottom-right {
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    .badge {

      &.badge--rotate-left {
        transform: rotateZ($badge-rotation *-1);
      }

      &.badge--rotate-right {
        transform: rotateZ($badge-rotation);
      }
    }
  }

  // responsive positions
  &-row--xs-bottom-left .badge,
  &-row--xs-top-left .badge {
    @include media-breakpoint-down(xs) {
      align-self: flex-start;
    }
  }

  &-row--xs-bottom-right .badge,
  &-row--xs-top-right .badge {
    @include media-breakpoint-down(xs) {
      align-self: flex-end;
    }
  }

  &-row--xs-bottom-center .badge,
  &-row--xs-top-center .badge {
    @include media-breakpoint-down(xs) {
      align-self: center;
    }
  }

  &-row--sm-bottom-left .badge,
  &-row--sm-top-left .badge {
    @include media-breakpoint-up(sm) {
      align-self: flex-start;
    }
  }

  &-row--sm-bottom-right .badge,
  &-row--sm-top-right .badge {
    @include media-breakpoint-up(sm) {
      align-self: flex-end;
    }
  }

  &-row--sm-bottom-center .badge,
  &-row--sm-top-center .badge {
    @include media-breakpoint-up(sm) {
      align-self: center;
    }
  }

  &-row--md-bottom-left .badge,
  &-row--md-top-left .badge {
    @include media-breakpoint-up(md) {
      align-self: flex-start;
    }
  }

  &-row--md-bottom-right .badge,
  &-row--md-top-right .badge {
    @include media-breakpoint-up(md) {
      align-self: flex-end;
    }
  }

  &-row--md-bottom-center .badge,
  &-row--md-top-center .badge {
    @include media-breakpoint-up(md) {
      align-self: center;
    }
  }

  &-row--lg-bottom-left .badge,
  &-row--lg-top-left .badge {
    @include media-breakpoint-up(lg) {
      align-self: flex-start;
    }
  }

  &-row--lg-bottom-right .badge,
  &-row--lg-top-right .badge {
    @include media-breakpoint-up(lg) {
      align-self: flex-end;
    }
  }

  &-row--lg-bottom-center .badge,
  &-row--lg-top-center .badge {
    @include media-breakpoint-up(lg) {
      align-self: center;
    }
  }

  &--small {
    width: rem($stage-badgesize-small);
    height: rem($stage-badgesize-small);
  }

  &--medium {
    width: rem($stage-badgesize-medium);
    height: rem($stage-badgesize-medium);
  }

  &--large {
    width: rem($stage-badgesize-large);
    height: rem($stage-badgesize-large);
  }

  &--responsive {
    width: 15vw;
    height: 15vw;
    min-width: rem($stage-badgesize-small);
    min-height: rem($stage-badgesize-small);
    max-width: rem($stage-badgesize-large);
    max-height: rem($stage-badgesize-large);
  }

  &--xs-small {
    @include media-breakpoint-down(xs) {
      width: rem($stage-badgesize-small);
      height: rem($stage-badgesize-small);
    }
  }

  &--xs-medium {
    @include media-breakpoint-down(xs) {
      width: rem($stage-badgesize-medium);
      height: rem($stage-badgesize-medium);
    }
  }

  &--xs-large {
    @include media-breakpoint-down(xs) {
      width: rem($stage-badgesize-large);
      height: rem($stage-badgesize-large);
    }
  }

  &--sm-small {
    @include media-breakpoint-up(sm) {
      width: rem($stage-badgesize-small);
      height: rem($stage-badgesize-small);
    }
  }

  &--sm-medium {
    @include media-breakpoint-up(sm) {
      width: rem($stage-badgesize-medium);
      height: rem($stage-badgesize-medium);
    }
  }

  &--sm-large {
    @include media-breakpoint-up(sm) {
      width: rem($stage-badgesize-large);
      height: rem($stage-badgesize-large);
    }
  }

  &--md-small {
    @include media-breakpoint-up(md) {
      width: rem($stage-badgesize-small);
      height: rem($stage-badgesize-small);
    }
  }

  &--md-medium {
    @include media-breakpoint-up(md) {
      width: rem($stage-badgesize-medium);
      height: rem($stage-badgesize-medium);
    }
  }

  &--md-large {
    @include media-breakpoint-up(md) {
      width: rem($stage-badgesize-large);
      height: rem($stage-badgesize-large);
    }
  }

  &--lg-small {
    @include media-breakpoint-up(lg) {
      width: rem($stage-badgesize-small);
      height: rem($stage-badgesize-small);
    }
  }

  &--lg-medium {
    @include media-breakpoint-up(lg) {
      width: rem($stage-badgesize-medium);
      height: rem($stage-badgesize-medium);
    }
  }

  &--lg-large {
    @include media-breakpoint-up(lg) {
      width: rem($stage-badgesize-large);
      height: rem($stage-badgesize-large);
    }
  }

  &--xl-small {
    @include media-breakpoint-up(xl) {
      width: rem($stage-badgesize-small);
      height: rem($stage-badgesize-small);
    }
  }

  &--xl-medium {
    @include media-breakpoint-up(xl) {
      width: rem($stage-badgesize-medium);
      height: rem($stage-badgesize-medium);
    }
  }

  &--xl-large {
    @include media-breakpoint-up(xl) {
      width: rem($stage-badgesize-large);
      height: rem($stage-badgesize-large);
    }
  }



  &--red {
    background-color: $red;
  }

  &--blue {
    background-color: $blue;
  }

  &--yellow {
    background-color: $yellow;
  }

  &--green {
    background-color: $green;
  }

  &--black {
    background-color: $black;
  }

  &--white {
    background-color: $white;
  }

  &__text {
    &--small {
      font-size: $h6-font-size;
    }

    &--medium {
      font-size: $text-font-size;
    }

    &--large {
      font-size: $h2-font-size;
    }

    &--responsive {
      font-size: $h6-font-size;

      @include media-breakpoint-up(sm) {
        font-size: $text-font-size;
      }

      @include media-breakpoint-up(md) {
        font-size: $h5-font-size;
      }

      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
      }

      @include media-breakpoint-up(xl) {
        font-size: $text-font-size*2;
      }
    }

    // responsive text sizes
    &--xs {
      @include media-breakpoint-down(xs) {
        &-small {
          font-size: $h6-font-size;
        }

        &-medium {
          font-size: $text-font-size;
        }

        &-large {
          font-size: $h2-font-size;
        }
      }
    }

    &--sm {
      @include media-breakpoint-up(sm) {
        &-small {
          font-size: $h6-font-size;
        }

        &-medium {
          font-size: $text-font-size;
        }

        &-large {
          font-size: $h2-font-size;
        }
      }
    }

    &--md {
      @include media-breakpoint-up(md) {
        &-small {
          font-size: $h6-font-size;
        }

        &-medium {
          font-size: $text-font-size;
        }

        &-large {
          font-size: $h2-font-size;
        }
      }
    }

    &--lg {
      @include media-breakpoint-up(lg) {
        &-small {
          font-size: $h6-font-size;
        }

        &-medium {
          font-size: $text-font-size;
        }

        &-large {
          font-size: $h2-font-size;
        }
      }
    }

    &--xl {
      @include media-breakpoint-up(xl) {
        &-small {
          font-size: $h6-font-size;
        }

        &-medium {
          font-size: $text-font-size;
        }

        &-large {
          font-size: $h2-font-size;
        }
      }
    }


    &--black {
      color: $black;
    }

    &--white {
      color: $white;
    }
  }
}
