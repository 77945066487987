//import
@import "bootstrap/mixins/forms";
@import "bootstrap/forms";
@import "bootstrap/input-group";


.form-wrapper {
    color: $form-textcolor;
    font-size: $form-fontsize;

    .bg-dark & {
        color: $darkform-textcolor;
    }
}

.form-heading {
    @extend .text-wrap;
    @extend .text-break;
    hyphens: auto;
}

.label-text {
    display: block;
    font-family: $input-label-fontfamily;
    font-size: $input-label-fontsize;
    font-weight: $input-label-fontweight;
    line-height: $input-label-lineheight;
}
.input-group-prepend {
    & + .input-textfield,
    & + .input-emailfield {
        border-bottom: $input-compact-borderwidth solid $input-compact-bordercolor;
        .bg-dark & {
            border-color: $input-compact-bordercolorinverted;
        }
    }
    label {
        border-bottom: $input-compact-borderwidth solid $input-compact-bordercolor;

        .bg-dark & {
            border-color: $input-compact-bordercolorinverted;
        }
    }

    & + .form-control {
        & ~ .invalid-feedback {
            padding-left: $form-input-group-prepend-valid-padding;
        }
        & ~.valid-feedback {
            padding-left: $form-input-group-prepend-invalid-padding;
        }
    }
}
.input-group {
    //border-bottom: 1px solid $input-border-color;

    &-text {
        background-color: $input-bg;
        color: $input-color;
        border: none;

        .bg-dark & {
            background-color: $input-bg-inverted;
            color: $input-color-inverted;
        }
    }
    .bg-dark & {
        border-color: $input-border-color-inverted;
    }
}

.form-control {
    color: $input-color;

    &.input-select {
        -webkit-appearance: none;
        -moz-appearance: none;

        &.select-multiple {
            &:invalid {
                background-image: none;
            }
        }
    }
    &.select-dropdown {
        //background-image: $dropdown-icon-expand--black; alt
        @extend %icon-expand--black;

        background-repeat: no-repeat;
        background-position: right center;
        background-size: rem(32) rem(32);
        transition: 0.2s all ease-in-out;

        &:focus {
            @extend .select-dropdown;
            // background-image: $dropdown-icon-minimize--black; alt
            @extend %icon-minimize--black;
        }

        .bg-dark & {
            @extend .select-dropdown;
            // background-image: $dropdown-icon-expand--white; alt
            @extend %icon-expand--white;

            &:focus {
                @extend .select-dropdown;
                // background-image: $dropdown-icon-minimize--white; alt
                @extend %icon-minimize--white;
            }
        }
        .was-validated & {
            &:valid,
            &:invalid {
                @extend .select-dropdown;
                @extend %icon-expand--black;
                // background-image: $dropdown-icon-expand--black; alt

                &:focus {
                    @extend .select-dropdown;
                    // background-image: $dropdown-icon-minimize--black; alt
                    @extend %icon-minimize--black;
                }

                .bg-dark & {
                    @extend .select-dropdown;
                    // background-image: $dropdown-icon-expand--white; alt
                    @extend %icon-expand--white;

                    &:focus {
                        @extend .select-dropdown;
                        // background-image: $dropdown-icon-minimize--white; alt
                        @extend %icon-minimize--white;
                    }
                }
            }
        }

    }
    &.input-textfield,
    &.input-emailfield {
        border-left: none;
        border-right: none;
        border-top: none;

        &:required {
            box-shadow: none;
        }
        .was-validated & {
            &:valid {
                color: $form-feedback-valid-color;
                &:focus {
                    color: $input-color;

                    .bg-dark & {
                        color: $input-border-color-inverted;
                    }
                }
            }
            &:invalid {
                color: $form-feedback-invalid-color;
                &:focus {
                    color: $input-color;

                    .bg-dark & {
                        color: $input-color-inverted;
                    }
                }
            }
        }

        &:valid:focus,
        &:invalid:focus {
            .was-validated & {
                box-shadow: none;
            }
        }
    }

    &.input-textfield,
    &.input-emailfield,
    &.input-textfield-block,
    &.input-emailfield-block {
        &[value=""]:valid {
            .was-validated & {
                background: none;
            }
        }
    }

    &.input-textfield-block,
    &.input-emailfield-block {
        &[value=""]:valid {
            .was-validated & {
                border-color: $input-border-color;

                .bg-dark &{
                    border-color: $input-border-color-inverted;
                }
            }
        }
    }
    &.input-textfield-block,
    &.input-emailfield-block {
        .bg-dark & {
            border: 1px solid $input-border-color-inverted;
        }
    }
    &.input-textarea {
        &:empty:valid {
            background: none;
            border-color: $input-border-color;

            .bg-dark & {
                border-color: $input-border-color-inverted;
            }
        }
        &:valid {
            border-color: $form-feedback-valid-color;
            .bg-dark & {
                border-color: $form-feedback-valid-color;
            }
        }
        &:invalid {
            border-color: $form-feedback-invalid-color;
            .bg-dark & {
                border-color: $form-feedback-invalid-color;
            }
        }
    }

    &:focus {
        background-color: $input-bg;
        color: $input-color;
        border-color: $input-border-color;
        box-shadow: none;
        .was-validated & {
            color: $input-color;
        }
    }
    &::placeholder {
        text-align: right;
    }
    &:invalid {
        .was-validated & {
            &:focus {
                box-shadow: none;
                border-color: $input-border-color;
            }
            .bg-dark & {
                border-color: $form-feedback-invalid-color;
                &:focus {
                    border-color: $input-border-color-inverted;
                }
            }
            &.input-textfield,
            &.input-emailfield {
                border-color: $input-border-color;
                .bg-dark & {
                    border-color: $input-border-color-inverted;
                }
            }
        }
    }
    &:valid {
        .was-validated & {

            .bg-dark & {
                border-color: $form-feedback-valid-color;
            }

            &.input-select {
               // background-image: none;
                padding-right: 0;
                &.select-multiple {
                    background-image: none;
                }
            }
            &:focus {
                box-shadow: none;
                border-color: $input-border-color;
                .bg-dark & {
                    border-color: $input-border-color-inverted;
                }
            }
            &.input-emailfield,
            &.input-textfield {
                border-color: $input-border-color;

                .bg-dark & {
                    border-color: $input-border-color-inverted;
                }
            }
        }
    }

    .bg-dark & {
        background-color: $input-bg-inverted;
        color: $input-color-inverted;

        &:focus {
            background-color: $input-bg-inverted;
            color: $input-color-inverted;
        }
        &.input-select,
        &.input-textarea,
        &.input-select:focus,
        &.input-textarea:focus {
            border: 1px solid $input-border-color-inverted;
            box-shadow: none;
        }
    }
}

.form-check {
    display: flex;
    align-items: center;

    &.form-check-inline {
        display: inline-flex;
        padding-left: rem($input-checkbox-padding-left);
    }
    &-input {
        margin-top: 0;
        top: rem($input-checkbox-position-left);

        .form-check-inline & {
            position: absolute;
            left: 0;
        }

        &[type="radio"]:required,
        &[type="checkbox"]:required {
            &:valid + label {
                color: $form-feedback-valid-color;
            }
            &:invalid + label {
                color: $form-feedback-invalid-color;
            }
        }
    }
}

.invalid-feedback {
    color: $form-feedback-invalid-color;
}
.valid-feedback {
    color: $form-feedback-valid-color;
}

// Added a honeypot for anti-spam

form .form-firstname {
  display: none;
}
