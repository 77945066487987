.text-image {
    line-height: $textimage-lineheight;
    font-weight: $textimage-fontweight;
    word-wrap: break-word;
    hyphens: auto;
    overflow-wrap: break-word;

    h1, .h1,
    h2, .h2,
    h3, .h3 {
        line-height: 1.2em;
        @extend .mb-3;
    }
    h1, .h1 {
      @extend .mb-4;
    }
    &__figure {
        @extend .mb-3;

        display: inline-block;
        flex-grow: 0;
        max-width: 100%;

        &-description {
            font-size: $textimage-figdescription-fontsize;
            margin-top: $textimage-figdescription-mgtop;
        }
    }
    &__figure img {
        min-width: 100%;
        max-width: 100%;
        min-height: 64px;
    }

    &__figure img[src*="svg"] {
      height: 4rem;
    }

    ul {
      li {
        a {
          @extend a;
          @extend .link;
          word-break: break-all;
        }
      }
    }
  .bg-dark & {
    img[src$=svg] {
      filter: invert(1);
    }
  }
}
