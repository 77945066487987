.tiles {
    color: $white;
}
.tiles--with-teasers {

    .tile:after {
        content: '';
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        padding-bottom: 100%;

        @media screen and (min-width: 400px) {
            padding-bottom: calc(50% - #{$grid-gutter-width-half});
        }
    }

    @include media-breakpoint-up(xs) {
        .col-xs-3 .tile:after {
            padding-bottom: calc(100%);
        }
        .col-xs-6 .tile:after {
            padding-bottom: calc(50% - #{$grid-gutter-width-half});
        }
    }
    @include media-breakpoint-up(sm) {
        .col-sm-3 .tile:after {
            padding-bottom: calc(100%);
        }
        .col-sm-6 .tile:after {
            padding-bottom: calc(50% - #{$grid-gutter-width-half});
        }
    }

    @include media-breakpoint-up(md) {
        .col-md-3 .tile:after {
            padding-bottom: calc(100%);
        }
        .col-md-6 .tile:after {
            padding-bottom: calc(100% - #{$grid-gutter-width-half});
        }
    }
    @include media-breakpoint-up(lg) {
        .col-lg-3 .tile:after {
            padding-bottom: calc(100%);
        }
        .col-lg-6 .tile:after {
            padding-bottom: calc(50% - #{$grid-gutter-width-half});
        }
    }
    @include media-breakpoint-up(xl) {
        .col-xl-3 .tile:after {
            padding-bottom: calc(100%);
        }
        .col-xl-6 .tile:after {
            padding-bottom: calc(50% - #{$grid-gutter-width-half});
        }
    }

    .tile__caption {
      background: $tiles-caption-bgcolor;
    }

    .tile:hover {
        .tile__caption {
          background: $tiles-caption-bgcolor-hover;
          *:not(a) {
              text-shadow: -10px 0px 50px $black;
          }
        }
    }
}
.tile {
    @extend .mb-4;
    position: relative;
    overflow: hidden;

    & .link-area{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &__img,
    img {
        max-width: 100%;

        .tiles--with-teasers & {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &__caption {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: $spacer*1.5;
		    transition: all .5s ease-in-out;

        font-size: rem($tiles-caption-fontsize);

        &-headline {
            font-weight: bold;
            font-size: rem($tiles-captionheadline-fontsize);
            line-height: rem($tiles-captionheadline-lineheight);
        }
        &-category {
            text-transform: uppercase;
        }
        &-link {

          &--wrapper {
            width: fit-content;
            position: absolute;
            bottom: $spacer*1.5;
            right: $spacer*1.5;

            z-index: 1;
          }
        }
    }
}
