@import "bootstrap/utilities/embed";

.video-module {
  position: relative;
  display: block;

  @include media-breakpoint-down(md) {
    &:before {
      content: unset;
    }
    video {
      position: relative!important;
    }
    height: 100vh;
  }

  .embed-responsive-item {
    padding: 0px 13px;

    iframe{
      padding: 0px 13px;
    }

    @include media-breakpoint-down(sm) {
      min-height: 80vh;
    }

    .uc-embedding-container {
      width: 100%!important;
      height: 100%!important;
      max-width: unset!important;
      max-height: unset!important;
    }
  }

  video {
    position: relative;
    object-fit: cover;

    .container &{
      margin: 0px 15px;
    }
    @include media-breakpoint-down(sm) {
      min-height: 80vh;
    }
  }

  .video-badge {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
  }
  .text-wrap {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    pointer-events: none;
    padding: 0 30px;
  }
  .text {
    width: 100%;
    margin: auto;
    color: white;
  }
  .play-wrap {
    @include media-breakpoint-up(md) {
      text-align: right;
      padding-right: 60px;
    }
  }
  .play {
    pointer-events: all;
    transition: all .5s ease-in-out;
    cursor: pointer;
    line-height: 35px;
    @include media-breakpoint-up(md) {
      line-height: 50px;
    }
    display: inline-block;
    padding: 10px;
    span {
      line-height: 35px;
      @include media-breakpoint-up(md) {
        line-height: 50px;
      }
      vertical-align: middle;
      display: inline-block;
    }
    &:hover {
      background-color: $white;
      color: $black;
      &:after {
        filter: invert(1);
      }
    }
    &:after {
      @extend %icon-play--white;
      transition: all .5s ease-in-out;
      content: '';
      background-size: cover;
      display: inline-block;
      vertical-align: middle;
      width: 35px;
      height: 35px;
      @include media-breakpoint-up(md) {
        width: 50px;
        height: 50px;
      }
    }
  }
  &.playing {
    .text-wrap {
      display: none;
    }
    .video-badge {
      display: none;
    }
  }
  .uc-embedding-container {
    max-height: unset;
    overflow: auto;
    .uc-embedding-wrapper {
      z-index: 1;
      font-family: $font-family-sans-serif;
      .uc-embedding-buttons > .uc-embedding-accept {
          background-color: #000;
      }
    }
  }
}
