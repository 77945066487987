.btn {
  display: inline-block;
  padding: 7px 20px;
  line-height: 21px;

  background-color: $btn-border-color;
  color: $btn-text-color;

  border: $btn-border-width solid transparent;

  align-items: center;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;

  font-size: $btn-font-size;
  font-weight: $btn-font-weight;
  text-transform: uppercase;
  user-select: none;

  transition: all 0.5s, transform 0.25s;

  &:hover {
    background-color: $btn-bgcolor-hover;
    color: $btn-text-color-hover;
  }

  &:focus {
    outline: 0;
    transform: scale(1.05);
    box-shadow: 0 0 0 $input-btn-focus-width $gray-400;
  }

  &:active {
    transform: scale(0.95);
  }

}

.btn-base {
  .bg-dark & {
    color: $white;

    &:hover {
      background-color: $white;
      color: $black;
    }
  }
}

.btn-border {
  @extend .btn;

  border-color: $button-grey;

  .bg-dark & {
    color: $white;
    border-color: $white;

    &:hover {
      background-color: $white;
      color: $black;
    }
  }
}

.btn-filled{
  background-color: $black;
  color: $white;

  &:hover {
    background-color: $cyan;
  }

  .bg-dark & {
    background-color: $white;
    color: $black;

    &:hover {
      background-color: $cyan;
      color: $white;
    }
  }
}

.btn-highlight, .bg-dark .btn-highlight {
  background-color: $cyan;
  color: $white;

  &:hover {
    background-color: $black;
  }

}

// Links

.btn-link {
  @extend .btn-base;
}
