// maintenance

.maintenance-page {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  // font-weight: 100;
  .text-center a {
    border-bottom: 3px solid $black;
    font-weight: $font-weight-bolder;
    &:hover {
      border-bottom-color: $link-hover-color;
    }
  }
  .maintenance--phone-icon,
  .maintenance--mail-icon {
    line-height: 1.2;
    font-size: 16px;
    &::before {
      content: '';
      background-size: contain;
      background-position: center center;
      display: inline-block;
      // position: absolute;
      vertical-align: middle;
      width: 24px;
      height: 36px;
      margin-right: 10px;
      margin-left: -36px;
      background-repeat: no-repeat;
      filter: brightness(0);
      // something is wrong with those icons…
      // filter: invert(1);
      // .footer & {
      //   filter: invert(0);
      // }
    }
    // .footer & {
    //   color: $footer-textcolor;
    // }
  }
  .maintenance--phone-icon::before {
    @extend %icon-phone--black;
  }

  .maintenance--mail-icon::before {
    @extend %icon-mail--black;
  }
}
