.numbers-module {
  .number-segment {
    // min-height: 350px;
    // padding: 100px 0;
    &.align-bigger-sm {
      @include media-breakpoint-down(sm) {
        text-align: center !important;
      }
    }
    &.align-bigger-md {
      @include media-breakpoint-down(md) {
        text-align: center !important;
      }
    }
    &.align-bigger-lg {
      @include media-breakpoint-down(lg) {
        text-align: center !important;
      }
    }
  }
  .number-content {
    width: 100%;
  }
  .number {
    font-size: rem(80px);
    font-weight: bold;
    font-family: $font-family-sans-serif;
  }
  .text {
    font-weight: lighter;
  }
}
